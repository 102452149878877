import './common';
import 'leaflet';
import "../scss/vendor/leaflet.css"

/**
 * Geo comparison code, used by both the demo and the internal comparison
 */
/* global L: false */
(function () {
    "use strict";
    const $compare = $("#compare");
    if ($compare.length === 0) {
        return;
    }
    
    // initialize the map
    const map = L.map('map', {
        maxZoom: 13
    }).setView([40.765396, -73.975954], 13, {animate: true});
    L.tileLayer('https://tiles.geolake.com/tile/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);
    let markerGroup = L.layerGroup().addTo(map);
    
    // show the initial value
    renderMapPin(40.765396, -73.975954, markerGroup);
    
    // on submit render the rest
    $compare.submit(function (e) {
        e.preventDefault();
        markerGroup.remove();
        markerGroup = L.layerGroup().addTo(map);

        const data = extractFormData();
        geocode(data);
    });
    
    /**
     * Grabs the user submitted form data.
     * @returns {{address: *, city: *, state: *, postalCode: *, country: *}}
     */
    function extractFormData() {
        return {
            "query": $compare.find("input[name=query]").val()
        };
    }
    
    /**
     * @param addressData   form data
     */
    function geocode(addressData) {
        $.ajax({
            type: "POST",
            url: $compare.attr("action") + "/geolake",
            data: addressData,
            success: function (response) {
                printApiResponse(response);
                if (response.errors) {
                    let errorMessages = response.errors.join(", ");
                    alert("Error: " + errorMessages);
                } else {
                    renderMapPin(response.lat, response.long, markerGroup);
                }
            },
            error: function (err, brr, hrr) {
                alert("Error: " + hrr);
            },
            dataType: "json"
        });
    }
    
    function renderMapPin(lat, long, markerGroup) {
        let iconUrl = $compare.find(".geolake .mapicon").attr("src");
        if (!iconUrl) {
            iconUrl = "/assets/images/leaflet/pin_green.svg";
        }
        const icon = L.icon({
            iconUrl: iconUrl,
            iconSize: [40, 50],
            iconAnchor: [17, 50],
            popupAnchor: [0, -48]
        });
        const marker = L.marker([lat, long], {"icon": icon});
        marker.addTo(markerGroup);
        map.setView([lat, long], 13, {animate: true});
        marker.bindPopup(lat + ", " + long).openPopup();
    }
    
    function printApiResponse(response) {
        console.log("RESP: " + response);
        console.log(response);
    }

})();